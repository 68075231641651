<template>
    <div>
        <el-row class="mar10" v-show="object === '1'">
            <el-col :offset="1">
                <el-table :data="objects" border style="width: 100%">
                    <el-table-column
                        label="组织编码"
                        prop="code"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        label="组织名称"
                        prop="name"
                        align="center"
                    ></el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <el-row class="mar10" v-show="object === '2'">
            <el-col :offset="1"> 区域未定 </el-col>
        </el-row>
        <el-row class="mar10" v-show="object === '3'">
            <el-col :offset="1">
                <el-table :data="objects" border style="width: 100%">
                    <el-table-column
                        label="终端编码"
                        prop="code"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        label="终端名称"
                        prop="name"
                        align="center"
                    ></el-table-column>
                    <el-table-column label="终端等级" align="center">
                        <template #default="{ row }">
                            {{ terminalType[row.terminalLevel] }}
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <el-row class="mar10" v-show="object === '4'">
            <el-col :offset="1">
                <el-table :data="objects" border style="width: 100%">
                    <el-table-column
                        label="经销商编码"
                        prop="code"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        label="经销商名称"
                        prop="name"
                        align="center"
                    ></el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <el-row>
            <el-col
                :span="24"
                style="
                    font-size: 16px;
                    height: 20px;
                    line-height: 20px;
                    font-weight: 600;
                    margin: 20px 0;
                "
                >分利规则配置</el-col
            >
        </el-row>
        <div class="tabs">
            <el-tabs v-model="ruleType">
                <el-tab-pane label="随单搭赠" disabled name="1">
                    <div class="proList margin10">
                        <div class="pro_label">产品</div>
                        <div
                            class="cost-select el-textarea el-input--small"
                            style="min-height: 32px"
                        >
                            <el-tag
                                style="margin-right: 10px"
                                v-for="(item, index) in products"
                                :key="index"
                                >{{ item.productName || "" }}</el-tag
                            >
                        </div>
                    </div>
                    <div class="stand margin10">
                        <el-form ref="form" label-width="100px">
                            <el-form-item label="搭赠计算标准">
                                <el-radio-group v-model="obj.mode" disabled>
                                    <el-radio label="1">单阶梯赠</el-radio>
                                    <el-radio label="2">多阶梯赠</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="stand_list">
                        <div class="stand_list_item">
                            <el-row>
                                <el-col
                                    :span="24"
                                    class="stand_item"
                                    v-for="item in obj.details"
                                    :key="item.id"
                                >
                                    <div>入库满</div>
                                    <div>
                                        <el-input
                                            v-model="item.standardNum"
                                            type="number"
                                            size="mini"
                                            disabled
                                            :inline="true"
                                        />
                                    </div>
                                    <div>箱,奖励</div>
                                    <div>
                                        <el-select
                                            v-model="item.rewardType"
                                            placeholder="请选择"
                                            :inline="true"
                                            size="mini"
                                            disabled
                                        >
                                            <el-option
                                                v-for="item in rewardOptions"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div
                                        class="cost-select el-textarea el-input--small"
                                        style="
                                            min-height: 32px;
                                            width: 400px;
                                            word-wrap: break-word;
                                        "
                                        v-if="item.rewardType == 2"
                                    >
                                        <div
                                            v-if="item.giftProductVos.length > 0"
                                            style="flex-wrap: wrap"
                                        >
                                            <el-tag
                                                v-for="(pitem, pindex) in item.giftProductVos"
                                                :key="pindex"
                                                >{{ pitem.productName || "" }}</el-tag
                                            >
                                        </div>
                                    </div>
                                    <div v-if="item.rewardType == 1">
                                        <el-input
                                            type="number"
                                            v-model="item.rewardNum"
                                            :inline="true"
                                            size="mini"
                                            disabled
                                        />元
                                    </div>
                                    <div v-if="item.rewardType == 2">
                                        各&nbsp;<el-input
                                            type="number"
                                            :inline="true"
                                            size="mini"
                                            disabled
                                            v-model="item.rewardNum"
                                        />瓶
                                    </div>
                                    <div class="stand_icon"></div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                    <div class="margin10">
                        <div>搭赠分利规则描述</div>
                        <el-input
                            type="textarea"
                            :rows="10"
                            placeholder="请输入搭赠分利规则描述"
                            v-model="obj.content"
                            disabled
                            resize="none"
                        >
                        </el-input>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="包量政策" disabled name="2">
                    <div class="policy">
                        <div>政策规则配置</div>
                    </div>
                    <el-row class="margin10">
                        <el-col>
                            <el-table :data="obj.ladderRespVo" border style="width: 100%">
                                <el-table-column
                                    label="阶梯编码"
                                    prop="ladderCode"
                                    align="center"
                                ></el-table-column>
                                <el-table-column
                                    label="阶梯名称"
                                    prop="ladderName"
                                    align="center"
                                ></el-table-column>
                                <el-table-column
                                    label="阶梯类型"
                                    prop="ladderType"
                                    align="center"
                                ></el-table-column>
                                <el-table-column
                                    label="总销量"
                                    prop="yearSaleNum"
                                    align="center"
                                ></el-table-column>
                                <el-table-column
                                    label="月度1"
                                    prop="januarySaleNum"
                                    align="center"
                                ></el-table-column>
                                <el-table-column
                                    label="月度2"
                                    prop="februarySaleNum"
                                    align="center"
                                ></el-table-column>
                                <el-table-column
                                    label="月度3"
                                    prop="marchSaleNum"
                                    align="center"
                                ></el-table-column>
                                <el-table-column
                                    label="月度4"
                                    prop="aprilSaleNum"
                                    align="center"
                                ></el-table-column>
                                <el-table-column
                                    label="月度5"
                                    prop="maySaleNum"
                                    align="center"
                                ></el-table-column>
                                <el-table-column
                                    label="月度6"
                                    prop="juneSaleNum"
                                    align="center"
                                ></el-table-column>
                                <el-table-column
                                    label="月度7"
                                    prop="julySaleNum"
                                    align="center"
                                ></el-table-column>
                                <el-table-column
                                    label="月度8"
                                    prop="augustSaleNum"
                                    align="center"
                                ></el-table-column>
                                <el-table-column
                                    label="月度9"
                                    prop="septemberSaleNum"
                                    align="center"
                                ></el-table-column>
                                <el-table-column
                                    label="月度10"
                                    prop="octoberSaleNum"
                                    align="center"
                                ></el-table-column>
                                <el-table-column
                                    label="月度11"
                                    prop="novemberSaleNum"
                                    align="center"
                                ></el-table-column>
                                <el-table-column
                                    label="月度12"
                                    prop="decemberSaleNum"
                                    align="center"
                                ></el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>

                    <div class="proList margin10">
                        <div class="pro_label">产品</div>
                        <div
                            class="cost-select el-textarea el-input--small"
                            style="min-height: 32px"
                        >
                            <el-tag v-for="(item, index) in products" :key="index">{{
                                item.productName || ""
                            }}</el-tag>
                        </div>
                    </div>
                    <div class="policy margin10">
                        <el-form ref="form" label-width="100px">
                            <el-form-item label="是否合并考核">
                                <el-radio-group v-model="obj.combineAssess" disabled>
                                    <el-radio label="1" name="1">是</el-radio>
                                    <el-radio label="0" name="0">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div v-if="jstFlag">
                        <div v-for="(item, index) in jtList" :key="index">
                            <div style="font-size: 14px; font-weight: 500">
                                阶梯{{ index + 1 }}
                            </div>
                            <div>
                                <div class="jtclass">
                                    <el-table :data="item.ladderTable" border>
                                        <el-table-column label="阶梯达成比例(%)" align="center">
                                            <template slot-scope="scope">
                                                <el-input
                                                    v-model="scope.row.ratio"
                                                    disabled
                                                    placeholder=""
                                                ></el-input>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="奖励类型" align="center">
                                            <template slot-scope="scope">
                                                <el-select v-model="scope.row.rewardType" disabled>
                                                    <el-option
                                                        v-for="item in rewardOptions"
                                                        :key="item.value"
                                                        :label="item.label"
                                                        :value="item.value"
                                                    >
                                                    </el-option>
                                                </el-select>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="奖励名称" align="center">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.rewardType == '1'">{{
                                                    scope.row.rewardName
                                                }}</span>
                                                <span v-else-if="scope.row.giftProductVos">
                                                    <el-tag
                                                        v-for="(pitem, pindex) in scope.row.giftProductVos"
                                                        :key="pindex"
                                                        disabled
                                                        >{{ pitem.productName || "" }}</el-tag
                                                    >
                                                </span>
                                                <span v-else></span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="奖励周期(可多选)" align="center">
                                            <template slot-scope="scope">
                                                <el-select
                                                    v-model="scope.row.rewardPeriod"
                                                    multiple
                                                    disabled
                                                    placeholder=""
                                                >
                                                    <el-option
                                                        v-for="item in rewardCycle"
                                                        :key="item.label"
                                                        :label="item.label"
                                                        :value="item.value"
                                                    >
                                                    </el-option>
                                                </el-select>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                            <div class="margin10">
                                <el-table :data="item.quantityProlist" border>
                                    <el-table-column
                                        label="产品名称"
                                        prop="productName"
                                        align="center"
                                        v-if="obj.combineAssess==0"
                                    ></el-table-column>
                                    <el-table-column
                                        label="产品编码"
                                        prop="productCode"
                                        align="center"
                                        v-if="obj.combineAssess==0"
                                    ></el-table-column>
                                    <el-table-column label="季现金奖励(元/箱)" align="center">
                                        <template slot-scope="scope">
                                            <el-input
                                                v-model="scope.row.quarterMoney"
                                                disabled
                                            ></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="半年现金奖励(元/箱)" align="center">
                                        <template slot-scope="scope">
                                            <el-input
                                                v-model="scope.row.halfYearMoney"
                                                disabled
                                                placeholder=""
                                            ></el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="年现金奖励(元/箱)" align="center">
                                        <template slot-scope="scope">
                                            <el-input
                                                v-model="scope.row.allYearMoney"
                                                disabled
                                                placeholder=""
                                            ></el-input>
                                        </template>
                                    </el-table-column>

                                </el-table>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="陈列政策" disabled name="3">
                    <div class="policy">
                        <div>政策规则配置</div>
                    </div>
                    <el-row class="margin10">
                        <el-col>
                            <el-table :data="obj.ladderRespVo" border style="width: 100%">
                                <el-table-column
                                    label="阶梯编码"
                                    prop="ladderCode"
                                    align="center"
                                ></el-table-column>
                                <el-table-column
                                    label="阶梯名称"
                                    prop="ladderName"
                                    align="center"
                                ></el-table-column>
                                <el-table-column
                                    label="货架陈列面数"
                                    prop="displayNumber"
                                    align="center"
                                ></el-table-column>
                                <el-table-column
                                    label="堆头陈列面数"
                                    prop="pileNumber"
                                    align="center"
                                ></el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>

                    <div class="proList margin10">
                        <div class="pro_label">产品</div>
                        <div
                            class="cost-select el-textarea el-input--small"
                            style="min-height: 32px"
                        >
                            <el-tag v-for="(item, index) in products" :key="index">{{
                                item.productName || ""
                            }}</el-tag>
                        </div>
                    </div>
                    <div class="dispaly_rules margin10">
                        <div class="stand margin10">
                            <el-form ref="form" label-width="100px">
                                <el-form-item label="上传规则配置">
                                    <el-radio-group v-model="obj.uploadConfig" disabled>
                                        <el-radio label="1">自定时间</el-radio>
                                        <el-radio label="2">固定时间上传</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="customized_time">
                            <div class="cycle">
                                <span>每</span>
                                <el-select v-model="obj.dateMode" disabled>
                                    <el-option
                                        v-for="item in selectcycle"
                                        :key="item.label"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                                <span>上传次数</span>
                                <el-input
                                    type="text"
                                    placeholder="请输入上传次数"
                                    style="width: 200px"
                                    v-model="obj.limitNum"
                                    disabled
                                ></el-input>
                                <span> 单次上传张数</span>
                                <el-input
                                    type="text"
                                    placeholder="请输入上传张数"
                                    v-model="obj.sheetNum"
                                    style="width: 200px"
                                    disabled
                                ></el-input>
                                <span> 通过率</span>
                                <el-input
                                    type="text"
                                    placeholder=""
                                    v-model="obj.passRate"
                                    style="width: 200px"
                                    disabled
                                ></el-input>
                            </div>
                        </div>
                        <div v-if="obj.uploadConfig === '1'">
                            <div class="customized_time">
                                <div class="cycle_content margin10">
                                    <div class="cycle_row">
                                        <div
                                            class="cycle_content_item"
                                            v-for="(fre, index) in obj.uploadRespVos"
                                            :key="index"
                                        >
                                            <span>第{{ index + 1 }}次上传时间：</span>
                                            <el-input
                                                placeholder="请输入内容"
                                                v-model="fre.startNum"
                                                style="width: 200px"
                                                disabled
                                                v-if="obj.dateMode === '2'"
                                            >
                                                <span slot="append" class="apend">号</span>
                                            </el-input>
                                            <el-select
                                                v-model="fre.startNum"
                                                placeholder=""
                                                disabled
                                                v-if="obj.dateMode === '1'"
                                            >
                                                <el-option
                                                    v-for="item in selectcycleDayOptions"
                                                    :key="item.label"
                                                    :label="item.label"
                                                    :value="item.value"
                                                >
                                                </el-option>
                                            </el-select>
                                            <span>&nbsp;&nbsp;至&nbsp;&nbsp;</span>
                                            <el-input
                                                placeholder="请输入内容"
                                                v-model="fre.endNum"
                                                style="width: 200px"
                                                disabled
                                                v-if="obj.dateMode === '2'"
                                            >
                                                <span slot="append" class="apend">号</span>
                                            </el-input>
                                            <el-select
                                                v-model="fre.endNum"
                                                placeholder=""
                                                disabled
                                                v-if="obj.dateMode === '1'"
                                            >
                                                <el-option
                                                    v-for="item in selectcycleDayOptions"
                                                    :key="item.label"
                                                    :label="item.label"
                                                    :value="item.value"
                                                >
                                                </el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="obj.uploadConfig === '2'">
                            <div class="customized_time">
                                <div class="cycle_content margin10">
                                    <div class="cycle_row">
                                        <div
                                            class="cycle_content_item"
                                            v-if="obj.dateMode === '1'"
                                        >
                                            <span>每周上传时间：</span>
                                            <div class="days_list">
                                                <div
                                                    :class="{ days_item: true, active: ditem.flag }"
                                                    v-for="(ditem, index) in selectcycleDayOptions"
                                                    :key="index"
                                                >
                                                    {{ ditem.label }}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="cycle_content_item"
                                            v-if="obj.dateMode === '2'"
                                        >
                                            <span>每月上传时间：</span>
                                            <div class="days_list">
                                                <div
                                                    :class="{ days_item: true, active: ditem.flag }"
                                                    v-for="(ditem, index) in days"
                                                    :key="index"
                                                >
                                                    {{ ditem.val }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="upPic">
                            <div class="weekly">
                                <span style="margin-right: 18px">货架示例图片：</span>
                                <el-image
                                    v-for="(img, index) in shelfSampleUrlArry"
                                    :key="index"
                                    style="width: 100px; height: 100px"
                                    :src="img"
                                    :preview-src-list="shelfSampleUrlArry"
                                >
                                </el-image>
                            </div>
                            <div class="weekly">
                                <span style="margin-right: 18px">堆头示例图片：</span>
                                <el-image
                                    v-for="(img, index) in pileSampleUrlArry"
                                    :key="index"
                                    style="width: 100px; height: 100px"
                                    :src="img"
                                    :preview-src-list="pileSampleUrlArry"
                                >
                                </el-image>
                            </div>
                        </div>
                        <div class="policy margin10">
                            <div>奖励规则配置</div>
                        </div>
                        <div>
                            <el-table :data="obj.detailRespVos" border>
                                <el-table-column label="阶梯达成比例(%)" align="center">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.ratio" disabled></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="奖励类型" align="center">
                                    <template slot-scope="scope">
                                        <el-select v-model="scope.row.rewardType" disabled>
                                            <el-option
                                                v-for="item in rewardOptions"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column label="奖励名称" align="center">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.rewardType == '1'">{{
                                            scope.row.rewardName
                                        }}</span>
                                        <span v-else-if="scope.row.rewardType == '2'">
                                            <el-tag
                                                v-for="(pitem, pindex) in scope.row.giftProductVos"
                                                :key="pindex"
                                                >{{ pitem.productName || "" }}</el-tag
                                            >
                                        </span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="奖励周期(可多选)" align="center">
                                    <template slot-scope="scope">
                                        <el-select
                                            v-model="scope.row.rewardPeriods"
                                            multiple
                                            disabled
                                            placeholder="请选择奖励周期"
                                        >
                                            <el-option
                                                v-for="item in rewardCycle"
                                                :key="item.label"
                                                :label="item.label"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column label="季度现金奖励(元/箱)" align="center">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.quarterMoney" disabled></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="半年现金奖励(元/箱)" align="center">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.halfYearMoney" disabled></el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column label="年现金奖励(元/箱)" align="center">
                                    <template slot-scope="scope">
                                        <el-input v-model="scope.row.allYearMoney" disabled></el-input>
                                    </template>
                                </el-table-column>

                            </el-table>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div class="proList margin10" v-if="ruleType=='2' || ruleType=='3'">
                <div class="pro_label" style="width: auto">
                    协议内容
                    <span class="prevArg" @click="prevArgeement('bl')"
                        >预览协议内容</span
                    >
                </div>
            </div>
        </div>
        <el-drawer title="协议预览" size="70%"
            :height="dialogHeight"
            :visible.sync="dialogTableVisible"
            :modal="true"
            :lock-scroll="true"
            :append-to-body="true"
                >
            <pdf
            :src="pdfurl"
            :page="curPage"
            @num-pages="getPageNum"
            ></pdf>
            <div class="footer">
            <el-button-group class="page">
                <el-button @click="prevPage" icon="el-icon-arrow-left" :disabled="curPage === 1">上一页</el-button>
                <el-button @click="nextPage" icon='el-icon-arrow-right' :disabled="!totalPage || curPage === totalPage">下一页</el-button>
            </el-button-group>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { log, re, string } from 'mathjs';
import request from '@/found/utils/request';
import pdf from 'vue-pdf';

export default {
  name: 'terminal',
  data() {
    return {
      ruleType: '',
      jtList: [],
      jstFlag: false,
      terminalType: {}, // 终端等级数据字典
      rewardOptions: [
        {
          value: '1',
          label: '现金',
        },
        {
          value: '2',
          label: '实物',
        },
      ],

      selectcycle: [
        {
          value: '1',
          label: '周',
        },
        {
          value: '2',
          label: '月',
        },
      ],
      selectcycleDayOptions: [
        {
          value: 1,
          label: '星期一',
          flag: false,
        },
        {
          value: 2,
          label: '星期二',
          flag: false,
        },
        {
          value: 3,
          label: '星期三',
          flag: false,
        },
        {
          value: 4,
          label: '星期四',
          flag: false,
        },
        {
          value: 5,
          label: '星期五',
          flag: false,
        },
        {
          value: 6,
          label: '星期六',
          flag: false,
        },
        {
          value: 7,
          label: '星期日',
          flag: false,
        },
      ],
      rewardCycle: [
        {
          value: '2',
          label: '按半年返',
        },
        {
          value: '1',
          label: '按年度返',
        },
        {
          value: '3',
          label: '按季度返',
        },
        {
          value: '4',
          label: '按月返',
        },
      ],
      pdfurl: '',
      urlsrc: '',
      dialogTableVisible: false,
      dialogHeight: '600px',
      curPage: 1, // 当前页
      totalPage: 1, // 总页数】
      days: [],
      selectDay: [],
    };
  },

  props: {
    obj: {
      type: Object,
      default() {
        return {};
      },
    },
    object: String,
    objects: {
      type: Array,
      default: () => [],
    },
    // ruleType: String,
    products: {
      type: Array,
      default: () => [],
    },
    pileSampleUrlArry: {
      type: Array,
      default: () => [],
    },
    shelfSampleUrlArry: {
      type: Array,
      default: () => [],
    },
    params: {
      type: Object,
      default: () => {},
    },
  },

  watch: {
    obj: {
      deep: true,
      immediate: false,
      handler(objtv) {
        const watchJsList = [];
        if (objtv.detailRespVos) {
          objtv.detailRespVos.forEach((v) => {
            watchJsList.push({
              ladderTable: [
                {
                  ratio: v.ratio,
                  rewardType: v.rewardType,
                  rewardName: v.rewardName,
                  giftProductVos: v.giftProductVos,
                  rewardPeriod: v.rewardPeriods,
                },
              ],
              quantityProlist: v.rebateVos,
            });
          });
          console.log(watchJsList);
          if (watchJsList.length > 0) {
            this.$forceUpdate();
            this.jstFlag = true;
            this.jtList = watchJsList;
          }

          console.log(this.jtList);
        }
        if (objtv.uploadConfig === '2') {
          objtv.uploadRespVos.forEach((outItem) => {
            if (objtv.dateMode === '1') {
              this.selectcycleDayOptions.forEach((inItem) => {
                const zItem = inItem; if (outItem.startNum === zItem.value) { zItem.flag = true; }
              });
            }
            if (objtv.dateMode === '2') {
              this.days.forEach((inItem) => {
                const zItem = inItem;
                if (outItem.startNum === zItem.val) { zItem.flag = true; }
              });
            }
          });
        }
      },
    },
  },

  components: {
    pdf,
  },
  created() {
    console.log('this');
    console.log(this);
    this.getDays();
  },
  async mounted() {
    await this.getDictSelect();
  },
  methods: {
    getDays() {
      const now = new Date();
      const year = now.getFullYear();
      let month = now.getMonth() + 1; // 月份从0开始，所以要加1
      if (month < 10) {
        month = `0${month}`;
      }
      const daysInMonth = new Date(year, month, 0).getDate();
      console.log(daysInMonth);
      const dates = [];
      for (let day = 1; day <= daysInMonth; day += 1) {
        console.log(day);
        dates.push({ flag: false, val: day });
      }
      this.days = dates;
      console.log(dates);
    },
    // 获取协议总页数
    getPageNum(val) {
      this.totalPage = val;
    },
    // 上一页
    prevPage() {
      if (this.curPage > 1) {
        this.curPage -= 1;
      }
    },
    // 下一页
    nextPage() {
      if (this.curPage < this.totalPage) {
        this.curPage += 1;
      }
    },
    // 数据字典
    async getDictSelect() {
      const params = ['terminal_type'];
      await request
        .post('/mdm/mdmdictdata/batchDictSelect', params)
        .then((res) => {
          if (res.success) {
            const obj = {};
            for (let i = 0; i < res.result.terminal_type.length; i += 1) {
              obj[res.result.terminal_type[i].dictCode] = res.result.terminal_type[i].dictValue;
            }
            this.terminalType = obj;
            console.log(obj);
          }
        });
    },

    prevArgeement(type) {
      console.log(this.pdfurl);
      this.curPage = 1;// 当前
      this.dialogTableVisible = true;
    //   const routeUrl = this.pdfurl; // 文件路径
    //   const url = encodeURIComponent(routeUrl);
    //   this.urlsrc = `https://view.xdocin.com/view?src=${url}`;
    },
  },
};
</script>

<style lang="less" scoped>
    .mar10 {
        margin-top: 10px;
    }
    .titlerule {
        width: 100%;
        font-size: 13px;
        font-weight: 500;
    }
    .margin10 {
        margin-top: 10px;
    }
    .proList {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .pro_label {
            font-size: 14px;
            color: #606266;
            width: 80px;
        }
    }
    .stand {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .pro_label {
            font-size: 16px;
            color: #333;
            width: 120px;
        }
    }
    .stand_item {
        display: flex;
        align-items: center;
        width: 100% !important;
        margin-bottom: 8px;
        div {
            display: flex;
            align-items: center;
            margin-right: 10px;
        }
        .stand_icon {
            margin-left: auto;
        }
    }
    .prevArg {
        font-size: 12px;
        color: #c34231;
        margin-left: 10px;
        cursor: pointer;
    }
    .policy {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div.last {
            display: flex;
            align-items: center;
            cursor: pointer;
            span {
                color: #c34231;
                font-size: 14px;
            }
        }
    }
    .jtclass {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-table {
            margin-right: 30px;
        }
    }
    .dispaly_rules {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
    }
    .customized_time {
        width: 100%;

        .cycle {
            display: flex;
            align-items: center;
            flex: 1;
            span {
                margin: 0 10px;
                display: inline-block;
            }
        }
        .cycle_content {
            width: auto;
            border: 1px solid #dddfe6;
            padding: 10px;
            box-sizing: border-box;
            .cycle_row {
                width: 100%;
                .cycle_content_item {
                    margin: 10px 0;
                    display: flex;
                    flex: auto;
                    .el-input {
                        display: flex;
                    }
                    .el-input-group__append {
                        display: inline-block;
                    }
                    .apend {
                        display: inline-block;
                        // height: 30px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
    .weekly {
        display: flex;
        margin: 10px 0;
    }
    .cost-select {
        position: relative;
        border: 1px solid #dddfe6;
        border-radius: 5px;
        padding: 5px;

        /deep/ .el-input__inner {
            padding-right: 30px;
        }

        .clear-icon {
            width: 25px;
            height: 100%;
            position: absolute;
            right: 5px;
            top: 0;
            text-align: center;
            color: #c0c4cc;
            transition: all 0.3s;
            padding-top: 1px;
            display: none;

            .el-icon-circle-close {
                width: 100%;
                font-size: 14px;
                cursor: pointer;
                transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
            }
        }

        &:hover {
            .clear-icon {
                display: block;
            }
        }
    }

    /deep/ .el-tabs__item.is-disabled {
        &:hover {
            color: #c0c4cc !important;
        }
    }
    /deep/ .el-tabs__item.is-active {
        color: #c34231 !important;
        &:hover {
            color: #c34231 !important;
        }
    }
    /deep/ .el-tag {
        margin: 0 10px;
    }
    .diaClas {
        height: 600px;
    }
    .footer {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 20px;
  width: 100%;
  height: 73px;
  z-index: 999;
  border-top: 1px solid #e4e4e4;
  justify-content: flex-end;
  .page {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .download{
    position: absolute;
    right: 50px;
    .el-button {
      margin-left: 8px;
    }
  }
}
 /deep/.el-drawer__header{
        color: #333;
        font-weight: bolder;
        span{
            font-size: 20px;
        }
    }
    .days_list {
        width: 80%;
        margin-left: 40px;
        display: flex;
        flex-wrap: wrap;
        .days_item {
            padding: 0 20px;
            box-sizing: border-box;
            height: 30px;
            margin-right: 10px;
            margin-bottom: 10px;
            border: 1px solid #606266;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            color: #333;
            &.active {
                background-color: #c34231;
                color: white;
                border: 1px solid #c34231;
            }
        }
    }
</style>
